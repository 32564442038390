<template>
    <div class="loginPage">
        <div class="title">
            馆藏文物预防性保护风险防控管理服务平台
        </div>
        <div class="boxMainCss">

            <div class="LPLeft"></div>
            <div class="LPlogin">
                <div class="topL">
                    系统登录
                </div>
                <div class="loginForm">
                    <el-form
                    ref="Form"
                    :model="Form"
                    :rules="rules"
                    label-width="0px"
                    size="large">
                        <el-form-item label="" prop="username">
                            <el-input 
                            @keyup.enter="handleLogin"
                            v-model="Form.username" 
                            placeholder="请输入用户名">
                                <template #prepend>
                                    <svg-icon icon-class="user"/>
                                </template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="" prop="password">
                            <el-input 
                            placeholder="请输入密码"
                            type="password"
                            show-password
                            v-model="Form.password" 
                            @keyup.enter="handleLogin">
                                <template #prepend>
                                    <svg-icon icon-class="unlock"/>
                                </template>
                            </el-input>
                        </el-form-item>
                        <el-form-item prop="code" label="">
                            <el-input
                            v-model="Form.code"
                            auto-complete="off"
                            placeholder="请输入验证码"
                            style="width: 70%"
                            @keyup.enter="handleLogin"
                            >
                                <template #prepend>
                                    <svg-icon icon-class="validCode"/>
                                </template>
                            </el-input>
                            <div class="login-code">
                                <img :src="codeUrl" @click="getCode">
                            </div>
                        </el-form-item>
                        <el-button 
                        style="width:100%;color:#fff" 
                        size="large" 
                        color="#3170FF" 
                        @click="handleLogin()"
                        :loading="loading"
                        dark>
                        <span v-if="!loading">登 录</span>
                        <span v-else>登 录 中...</span>
                        </el-button>
                    </el-form>
                    <div class="zc"><span class="zcp" @click="zcFun">注册用户</span></div>
                </div>
            </div>
        </div>
       <registerForm ref="registerForm"/>
    </div>
</template>
<script>
import { encrypt } from '@/utils/rsaEncrypt'
import Cookies from 'js-cookie'
import registerForm from './modules/register.vue'
import { getCodeImg } from '@/api/login.js'
export default {
    components:{
        registerForm
    },
    data(){
        return{
            codeUrl:null,
            loading:false,
            Form:{
                username:'',
                password:'',
                code:null,
            },
            currentCode:null,
            rules:{
               username:{required: true, message: '请输入用户名', trigger: 'blur'},
               password:{required: true, message: '请输入密码', trigger: 'blur'}, 
               code:{required: true, message: '请输入验证码', trigger: 'blur'},
            }
        }
    },
    mounted(){
        if(Cookies.get('point')=='406'){
            this.$notify.warning({
                title: '提示信息',
                message: '登录信息已经失效',
                showClose: false,
            })
        }
        this.getCode()
    },
    methods:{
        handleLogin() {
            this.$refs.Form.validate(valid => {
                const encryptPassword = encrypt(this.Form.password)
                if (valid) {
                    if(this.currentCode==null||this.currentCode.toUpperCase()!=this.Form.code.toUpperCase()){
                        this.$message.warning("验证码错误！")
                        return
                    }
                    this.loading = true
                    let formData = new FormData()
                    formData.append('name', this.Form.username)
                    formData.append('pwd', encryptPassword)
                    this.$store.dispatch('Login', formData).then((res) => {
                        this.loading = false
                        this.$router.push({name: 'openHome'})
                    }).catch((err) => {
                        this.loading = false
                        this.getCode()
                        // this.$notify.warning({
                        //     title: '提示信息',
                        //     message: err.msg,
                        //     showClose: false,
                        // })
                    })
                } else {
                    return false
                }
            })
        },
        zcFun(){
            this.$refs.registerForm.open()
        },
        getCode(){
            getCodeImg().then(r=>{
                this.codeUrl = 'data:image/png;base64,' + r.img
                this.currentCode = r.vcode
            })
        }
    }
}
</script>
<style lang="scss" scoped>
@font-face {
    font-family: pangmenzhengd;
    src: url('../../assets/font/pangmenzhengd.TTF');
}

    .loginPage{
        width: 100%;
        height: 100vh;
        padding: 20px 100px;
        box-sizing: border-box;
        background-image: url('../../assets/img/L-bg.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .title{
            font-size: 32px;
            margin-bottom: 30px;
            color: white;
            font-weight: 600;
        }
        .boxMainCss{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 550px;
        border-radius: 5px;
        overflow: hidden;
        .LPLeft{
            width: 500px;
            height: 100%;
            background-image: url('../../assets/img/L-left.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-color: white;
        }
    }
    .LPlogin{
        width: 450px;
        height: 100%;
        background: white; 
        text-align: center;
        padding: 50px 40px;
        box-sizing: border-box;
        .topL{
            display: inline-block;
            font-size: 28px;
            padding: 20px 10px;
            .imgCss{
                width: 40px;
                height: 40px;
                object-fit: contain;
                vertical-align:middle;
            }
        }
        .loginForm{
            margin-top: 50px;  
            .login-code {
                width: 30%;
                display: inline-block;
                height: 38px;
                float: right;
                img {
                    cursor: pointer;
                    vertical-align: middle
                }
            }
            /deep/.el-input-group__prepend{
                box-shadow:none;
                padding: 0px 0px;
                padding-right: 10px;
            }
            /deep/.el-input-group__prepend{
                background-color: rgba(0, 0, 255, 0);
                color: #999;
                font-size: 30px;
            }
            /deep/.el-input{
                --el-input-border-color:rgba(0, 0, 255, 0);
            }
            /deep/.el-input__wrapper{
                box-shadow:0px 0px 0px #153661 inset;
                border-bottom: 1px solid #999;
            }
            /deep/.el-form-item.is-error .el-input__wrapper{
                box-shadow: 0 0 0 0px red inset;
            }
/deep/.el-form-item--large{
    margin-bottom: 50px;
}      
        }
    }
}
.zc{
    width: 100%;
    text-align: right;
    margin-top: 10px;
    color: rgb(0, 179, 255);
    font-size: 14px;
    .zcp{
        cursor: pointer;
        display: inline-block;
    }
}




/deep/.el-tabs__nav-scroll{
    display: flex !important;
    justify-content: center !important;
}
/deep/.el-dialog__header{
    margin-right: 0px !important;
    background-color: rgb(48, 48, 231) !important;
}

/deep/.el-dialog__title{
    color: white !important;
}

</style>